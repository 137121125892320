/* eslint-disable react/forbid-prop-types */
import React from 'react';
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  makeStyles,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { coreRed } from '../../themeprovider/ccmTheme/colorPalette';

const useStyles = makeStyles({
  radioLegend: {
    color: 'black',
    fontSize: '16px',
    paddingBottom: '10px',
    lineHeight: '25px',
    paddingTop: '5px',
  },
  error: {
    paddingTop: '30px',
    fontSize: '14px',
    color: coreRed,
  },
});

const RadioList = (props) => {
  const { fieldName, label, options, value, onChange, error } = props;

  const classes = useStyles();

  return (
    <>
      <div className={classes.error}>
        <span>{error || '\u00A0'}</span>
      </div>
      <FormControl component="fieldset">
        <FormLabel component="legend" className={classes.radioLegend}>
          {label}
        </FormLabel>
        <RadioGroup
          aria-label={fieldName}
          name={fieldName}
          value={value}
          onChange={onChange}
        >
          {options.map((option) => (
            <FormControlLabel
              value={option}
              control={<Radio color="primary" />}
              label={option}
              key={option}
            />
          ))}
        </RadioGroup>
      </FormControl>
    </>
  );
};

export default RadioList;

RadioList.propTypes = {
  fieldName: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  error: PropTypes.string,
};

RadioList.defaultProps = {
  error: '',
};
