import React from 'react';
import { string, func, shape, number } from 'prop-types';
import { Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  coreRed,
  coreWaterBlue,
} from '../../themeprovider/ccmTheme/colorPalette';

const useStyles = makeStyles(() => ({
  input: {
    display: 'none',
  },
  uploadButton: {
    backgroundColor: coreWaterBlue,
  },
  description: {
    marginTop: '29px',
  },
  error: {
    paddingTop: '30px',
    fontSize: '14px',
    color: coreRed,
  },
}));

const FormUploadImage = ({
  fieldName,
  label,
  value,
  error,
  handleChange,
  setFieldValue,
  handleBlur,
  setFieldTouched,
}) => {
  const classes = useStyles();

  const handleFileUpload = (event) => {
    const {
      target: { files },
    } = event;

    handleChange(event);
    setFieldTouched(fieldName, true);
    setFieldValue(fieldName, files[0]);
  };

  return (
    <>
      {error && (
        <div data-testid="imageUploadError" className={classes.error}>
          <span aria-label="companyLogo-error">{error}</span>
        </div>
      )}
      <Typography variant="body1" data-testid="formLabel">
        {label}
      </Typography>
      {value && (
        <>
          <Typography variant="body1">{value.name}</Typography>
          <Button
            data-testid="removeImageButton"
            variant="contained"
            component="span"
            className={classes.uploadButton}
            onClick={() => {
              setFieldValue(fieldName);
            }}
          >
            Remove
          </Button>
        </>
      )}
      {!value && (
        <>
          <input
            id={fieldName}
            name={fieldName}
            accept="image/*"
            className={classes.input}
            type="file"
            onChange={handleFileUpload}
            onBlur={handleBlur}
            value={[value]}
          />
          <label htmlFor={fieldName}>
            <Button
              data-testid="uploadButton"
              variant="contained"
              component="span"
              className={classes.uploadButton}
            >
              Upload
            </Button>
          </label>
          <Typography className={classes.description}>
            Please upload a high resolution JPEG, PNG or GIF of your company
            logo. The maximum file size is 2MB.
          </Typography>
        </>
      )}
    </>
  );
};

FormUploadImage.propTypes = {
  fieldName: string.isRequired,
  label: string.isRequired,
  value: shape({
    name: string,
    type: string,
    size: number,
  }).isRequired,
  error: string.isRequired,
  handleChange: func.isRequired,
  setFieldValue: func.isRequired,
  handleBlur: func.isRequired,
  setFieldTouched: func.isRequired,
};

export default FormUploadImage;
