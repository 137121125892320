/**
 * Convert an uploaded File object to dataUrl as a promise
 * @param {Input File} file
 */
function convertFileToBase64(file) {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();

    fileReader.onloadend = () => {
      resolve(fileReader.result);
    };

    fileReader.onerror = (error) => {
      reject(error);
    };

    fileReader.readAsDataURL(file);
  });
}

export { convertFileToBase64 };
