/* eslint-disable react/jsx-wrap-multilines */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormControl, Grid, Select, makeStyles } from '@material-ui/core';
import FormTextInput from '../FormTextInput/FormTextInput';
import { coreRed } from '../../themeprovider/ccmTheme/colorPalette';

const MONTHS = {
  1: 'January',
  2: 'February',
  3: 'March',
  4: 'April',
  5: 'May',
  6: 'June',
  7: 'July',
  8: 'August',
  9: 'September',
  10: 'October',
  11: 'November',
  12: 'December',
};

const useStyles = makeStyles({
  monthContainer: {
    width: '100%',
    '& .MuiInput-formControl': {
      margin: '0px',
    },
  },
  monthLabel: {
    paddingBottom: '10px',
    paddingTop: '5px',
    display: 'flex',
    alignItems: 'center',
  },
  monthSelect: {
    backgroundColor: '#FDF6CF',
    borderRadius: '4px 4px 0px 0px',
  },
  error: {
    paddingTop: '30px',
    fontSize: '14px',
    color: coreRed,
  },
  fieldset: {
    border: 'none',
  },
  legend: {
    paddingBottom: '10px',
    paddingTop: '5px',
  },
});

const DatePicker = (props) => {
  const { handleChange, error, legend } = props;
  const classes = useStyles();

  const CURRENT_DATE = new Date();
  const CURRENT_DAY = CURRENT_DATE.getDate().toString();
  const CURRENT_MONTH = (CURRENT_DATE.getMonth() + 1).toString();
  const CURRENT_YEAR = CURRENT_DATE.getFullYear().toString();

  const [day, setDay] = useState(CURRENT_DAY);
  const [month, setMonth] = useState(CURRENT_MONTH);
  const [year, setYear] = useState(CURRENT_YEAR);

  useEffect(() => {
    handleChange(
      `${day}/${month.toString().length === 1 ? `0${month}` : month}/${year}`,
    );
  }, [day, month, year]);

  return (
    <>
      <div className={classes.error}>{error || '\u00A0'}</div>
      <fieldset className={classes.fieldset}>
        <legend className={classes.legend}>{legend}</legend>
        <Grid container item direction="row" justify="space-between" xs={10}>
          <Grid item xs={3}>
            <FormTextInput
              fieldName="day"
              handleChange={(event) => setDay(event.target.value)}
              value={day}
              error={false}
              label="Day"
              type="number"
              textFieldInputProps={{ min: 1, max: 31, maxLength: 2 }}
            />
          </Grid>

          <Grid item xs={5}>
            <FormControl classes={{ root: classes.monthContainer }}>
              <label htmlFor="month" className={classes.monthLabel}>
                Month
              </label>

              <Select
                id="month"
                native
                value={month}
                onChange={(event) => {
                  setMonth(event.target.value);
                }}
                className={classes.monthSelect}
              >
                {Object.keys(MONTHS).map((key) => (
                  <option value={key} key={key}>
                    {MONTHS[key]}
                  </option>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={3}>
            <FormTextInput
              fieldName="year"
              handleChange={(event) => setYear(event.target.value)}
              value={year}
              error={false}
              label="Year"
              type="number"
              textFieldInputProps={{
                max: CURRENT_DATE.getFullYear(),
                maxLength: 4,
              }}
            />
          </Grid>
        </Grid>
      </fieldset>
    </>
  );
};

export default DatePicker;

DatePicker.propTypes = {
  handleChange: PropTypes.func.isRequired,
  error: PropTypes.string,
  legend: PropTypes.string.isRequired,
};

DatePicker.defaultProps = {
  error: '',
};
