/* eslint-disable react/jsx-wrap-multilines */
import React, { useState } from 'react';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { useFormik } from 'formik';
import RecaptchaInput from '../components/RecaptchaInput/RecaptchaInput';
import Layout from '../components/layout';
import FormTextInput from '../components/FormTextInput/FormTextInput';
import RadioList from '../components/RadioList/RadioList';
import DatePicker from '../components/DatePicker/DatePicker';
import FormSubmission from '../components/FormSubmission/FormSubmission';
import FormPrivacyCheckbox from '../components/FormPrivacyCheckbox/FormPrivacyCheckbox';
import { coreRed } from '../themeprovider/ccmTheme/colorPalette';
import {
  dateFieldError,
  emailFieldError,
  phoneFieldError,
  validateLogo,
  GENERIC_EMPTY_TEXT_FIELD_ERROR_STRING,
  textFieldError,
  urlFieldError,
} from '../utils/validation';
import { convertFileToBase64 } from '../utils/fileHelpers';

import { addService } from '../api';
import FormUploadImage from '../components/FormUploadImage/FormUploadImage';
import SEO from '../components/seo';

const useStyles = makeStyles((theme) => ({
  page: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0px 20px',
    marginTop: '30px',
    [theme.breakpoints.up('lg')]: {
      margin: '50px 0px 0px 200px',
      width: '80%',
    },
  },
  bodyText: {
    lineHeight: '140%',
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '30px',
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '62px',
    padding: '30px',
    backgroundColor: 'rgba(249, 249, 249, 0.3)',
    border: '1px solid #E0E0E0',
  },
  submitButton: {
    backgroundColor: '#293745',
    borderRadius: '50px',
    marginTop: '30px',
    fontWeight: 600,
    fontSize: '18px',
    color: '#FFFFFF',
    lineHeight: '25px',
    alignItems: 'center',
    textAlign: 'center',
    letterSpacing: '0.0357143em',
    textTransform: 'uppercase',
    width: '198px',
    height: '55px',
  },
  radioLegend: {
    color: 'black',
    fontSize: '16px',
    paddingBottom: '10px',
    paddingTop: '10px',
  },
  reCaptchaContainer: {
    margin: '30px auto 0',
  },
  error: {
    paddingTop: '20px',
    fontSize: '14px',
    color: coreRed,
  },
  noMargin: {
    margin: '0px',
  },
  socialMediaChannelsContainer: {
    marginTop: '30px',
  },
  checkboxLabel: {
    padding: '10px 0px',
  },
  checkbox: {
    width: '10px',
    height: '10px',
    marginRight: '10px',
    marginLeft: '10px',
    background: '#FDF6CF',
  },
  fields: {
    [theme.breakpoints.up('lg')]: {
      width: '520px',
    },
  },
}));

const AddServiceForm = () => {
  const MAX_SERVICE_OFFERING_LENGTH = 500;
  const MAX_SERVICE_LOCATION_LENGTH = 200;
  const MAX_SERVICE_ACCESS_LENGTH = 300;
  const MAX_ORGANISATION_INFORMATION_LENGTH = 300;
  const MAX_PATIENT_REFERRALS_LENGTH = 500;
  const MAX_ONGOING_SERVICES_LENGTH = 500;
  const MAX_HOW_FUNDED_LENGTH = 500;
  const MAX_NAME_LENGTH = 200;
  const MAX_SOCIAL_MEDIA_CHANNELS_LENGTH = 300;
  const ORGANISATION_ONLINE_OPTIONS = ['Yes', 'No'];
  const LOCATION_COUNT_OPTIONS = ['1 - 5', '6 - 10', '11 - 20', '21+'];
  const SERVICE_REACH_OPTIONS = ['National', 'Regional'];
  const FREE_SERVICE_OPTIONS = ['Yes', 'No'];
  const WRITE_BLOG_OPTIONS = ['Yes', 'No'];

  const [checked, setChecked] = useState(false);
  const [submitSuccessful, setSubmitSuccessful] = useState(null);
  const [reCaptchaToken, setReCaptchaToken] = useState(null);
  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const classes = useStyles();
  const formik = useFormik({
    initialValues: {
      url: '',
      serviceOffering: '',
      serviceLocation: '',
      serviceAccess: '',
      organisationInformation: '',
      companyLogo: '',
      registrationNumber: '',
      onlineService: '',
      locationCount: '',
      serviceReach: '',
      serviceRegions: {
        Scotland: false,
        'Northern Ireland': false,
        Wales: false,
        'North East': false,
        'North West': false,
        'Yorkshire & Humber': false,
        'West Midlands': false,
        'East Midlands': false,
        'South West': false,
        'South East': false,
        'East of England': false,
        London: false,
      },
      patientReferrals: '',
      ongoingServices: '',
      howFunded: '',
      freeService: '',
      name: '',
      email: '',
      phone: '',
      socialMediaChannels: '',
      writeBlog: '',
      date: '',
      recaptcha: '',
    },
    onSubmit: async (values, helpers) => {
      try {
        const companyLogoDataURL = await convertFileToBase64(
          values.companyLogo,
        );

        const apiModel = {
          ...values,
          companyLogo: companyLogoDataURL,
        };

        // try to send email
        await addService(apiModel, reCaptchaToken);
        // on success, show the 'thanks' message
        setSubmitSuccessful(true);
      } catch (err) {
        // and show some sort of error/retry message
        setSubmitSuccessful(false);
        helpers.setSubmitting(false);
      }
    },
    validate: (values) => {
      const errors = {};

      errors.url = urlFieldError(values.url);

      errors.serviceOffering = textFieldError(values.serviceOffering, {
        mandatory: true,
        characterLimit: MAX_SERVICE_OFFERING_LENGTH,
      });

      errors.serviceLocation = textFieldError(values.serviceLocation, {
        mandatory: true,
        characterLimit: MAX_SERVICE_LOCATION_LENGTH,
      });

      errors.serviceAccess = textFieldError(values.serviceAccess, {
        mandatory: true,
        characterLimit: MAX_SERVICE_ACCESS_LENGTH,
      });

      errors.organisationInformation = textFieldError(
        values.organisationInformation,
        {
          mandatory: true,
          characterLimit: MAX_ORGANISATION_INFORMATION_LENGTH,
        },
      );

      const companyLogoError = validateLogo(values.companyLogo);
      if (companyLogoError) {
        errors.companyLogo = companyLogoError;
      }

      if (!values.onlineService) {
        errors.onlineService = GENERIC_EMPTY_TEXT_FIELD_ERROR_STRING;
      }

      if (
        values.onlineService === ORGANISATION_ONLINE_OPTIONS[1] &&
        !values.locationCount
      ) {
        errors.locationCount = GENERIC_EMPTY_TEXT_FIELD_ERROR_STRING;
      }

      if (!values.serviceReach) {
        errors.serviceReach = GENERIC_EMPTY_TEXT_FIELD_ERROR_STRING;
      }

      if (
        values.serviceReach === SERVICE_REACH_OPTIONS[1] &&
        Object.keys(values.serviceRegions).every(
          (key) => !values.serviceRegions[key],
        )
      ) {
        errors.serviceRegions = 'Please select at least one region';
      }

      errors.patientReferrals = textFieldError(values.patientReferrals, {
        mandatory: true,
        characterLimit: MAX_PATIENT_REFERRALS_LENGTH,
      });

      errors.ongoingServices = textFieldError(values.ongoingServices, {
        mandatory: true,
        characterLimit: MAX_ONGOING_SERVICES_LENGTH,
      });

      errors.howFunded = textFieldError(values.howFunded, {
        mandatory: true,
        characterLimit: MAX_HOW_FUNDED_LENGTH,
      });

      if (!values.freeService) {
        errors.freeService = GENERIC_EMPTY_TEXT_FIELD_ERROR_STRING;
      }

      errors.name = textFieldError(values.name, {
        mandatory: true,
        characterLimit: MAX_NAME_LENGTH,
      });

      errors.email = emailFieldError(values.email);

      errors.phone = phoneFieldError(values.phone);

      errors.socialMediaChannels = textFieldError(values.socialMediaChannels, {
        characterLimit: MAX_SOCIAL_MEDIA_CHANNELS_LENGTH,
      });

      errors.date = dateFieldError(values.date);

      if (!values.writeBlog) {
        errors.writeBlog = GENERIC_EMPTY_TEXT_FIELD_ERROR_STRING;
      }

      if (!checked) {
        errors.checked = 'Please check this box to proceed';
      }

      Object.keys(errors).forEach(
        (key) => errors[key] === undefined && delete errors[key],
      );

      if (!values.recaptcha) {
        errors.recaptcha = 'Please verify the Recaptcha box below';
      }

      return errors;
    },
  });

  const scrollToError = (errors) => {
    const keys = Object.keys(errors);

    if (keys.length > 0) {
      // We grab the first input element that error by its name, if not found, then by id.
      const errorElement =
        document.querySelector(`label[for="${keys[0]}"]`) ||
        document.querySelector(`[aria-label="${keys[0]}"]`) ||
        document.querySelector(`[aria-label="${keys[0]}-error"]`);

      if (errorElement) {
        // When there is an input, scroll this input into view.
        errorElement.parentNode.previousSibling.scrollIntoView({
          block: 'start',
          behavior: 'smooth',
        });
        const textInput = document.getElementById(keys[0]);
        if (textInput) {
          textInput.focus();
        }
      }
    }
  };

  const validateRecapture = (token) => {
    setReCaptchaToken(token);
    formik.values.recaptcha = token;
  };

  return (
    <Layout>
      <SEO title="Add service" />
      {submitSuccessful === null ? (
        <div>
          <Grid container className={classes.page}>
            <Grid item lg={8}>
              <Typography variant="h1" className={classes.bodyText}>
                Add a service for review
              </Typography>
              <Typography variant="body1" className={classes.bodyText}>
                To be listed on cancercaremap.org please provide us with the
                following information. Once you have submitted your information,
                we will review to ensure that all listed services meet with our
                own &apos;cancer care criteria&apos;.
              </Typography>
              <Typography variant="body1" className={classes.bodyText}>
                We are a very small team so it could take up to 10 days. Once we
                are happy with your submission we will publish it on Cancer Care
                Map. If we have any queries we will be in touch in the meantime.
              </Typography>
              <Typography variant="body1" className={classes.bodyText}>
                <strong>
                  All fields are required unless indicated otherwise
                </strong>
              </Typography>
            </Grid>
            <form
              onSubmit={formik.handleSubmit}
              className={classes.formContainer}
            >
              <Grid item lg={8}>
                <Typography variant="h2" className={classes.noMargin}>
                  Information that will appear in your listing
                </Typography>
              </Grid>
              <Grid item className={classes.fields}>
                <FormTextInput
                  fieldName="url"
                  handleChange={formik.handleChange}
                  value={formik.values.url}
                  error={!!formik.touched.url && formik.errors.url}
                  showErrorText
                  label="URL of the webpage you would like your listing to link to"
                  touched={formik.touched.url}
                  handleBlur={formik.handleBlur}
                />

                <FormTextInput
                  fieldName="serviceOffering"
                  handleChange={formik.handleChange}
                  value={formik.values.serviceOffering}
                  error={
                    !!formik.touched.serviceOffering &&
                    formik.errors.serviceOffering
                  }
                  showErrorText
                  label="What does your service offer?"
                  tooltip
                  tooltipText="Please provide an overview of your organisation and the services offered – do not include opening times, or dates of particular sessions."
                  multiLine
                  rows={10}
                  maxCharacters={MAX_SERVICE_OFFERING_LENGTH}
                  showCharacterCount
                  handleBlur={formik.handleBlur}
                />

                <FormTextInput
                  fieldName="serviceLocation"
                  handleChange={formik.handleChange}
                  value={formik.values.serviceLocation}
                  error={
                    !!formik.touched.serviceLocation &&
                    formik.errors.serviceLocation
                  }
                  showErrorText
                  label="Where is your service or organisation based?"
                  tooltip
                  tooltipText="Please provide details of the location – ie. The Dimbleby Cancer Care centre is on the ground floor of the Welcome Village at Guy’s Cancer Centre."
                  multiLine
                  rows={10}
                  maxCharacters={MAX_SERVICE_LOCATION_LENGTH}
                  showCharacterCount
                  handleBlur={formik.handleBlur}
                />

                <FormTextInput
                  fieldName="serviceAccess"
                  handleChange={formik.handleChange}
                  value={formik.values.serviceAccess}
                  error={
                    !!formik.touched.serviceAccess &&
                    formik.errors.serviceAccess
                  }
                  showErrorText
                  label="Who can access your services?"
                  tooltip
                  tooltipText="Please provide details of how people can refer to the services and any eligibility criteria."
                  multiLine
                  rows={10}
                  maxCharacters={MAX_SERVICE_ACCESS_LENGTH}
                  showCharacterCount
                  handleBlur={formik.handleBlur}
                />

                <FormTextInput
                  fieldName="organisationInformation"
                  handleChange={formik.handleChange}
                  value={formik.values.organisationInformation}
                  error={
                    !!formik.touched.organisationInformation &&
                    formik.errors.organisationInformation
                  }
                  showErrorText
                  label="Information about your organisation"
                  tooltip
                  tooltipText="Please provide details of any parent organisation. I.e. if your service/centre is based within an NHS hospital."
                  multiLine
                  rows={10}
                  maxCharacters={MAX_ORGANISATION_INFORMATION_LENGTH}
                  showCharacterCount
                  handleBlur={formik.handleBlur}
                />
              </Grid>
              <br />

              <Grid item className={classes.fields}>
                <FormUploadImage
                  label="Logo"
                  fieldName="companyLogo"
                  value={formik.values.companyLogo}
                  error={
                    formik.touched.companyLogo && formik.errors.companyLogo
                  }
                  handleChange={formik.handleChange}
                  setFieldValue={formik.setFieldValue}
                  setFieldTouched={formik.setFieldTouched}
                  handleBlur={formik.handleBlur}
                />
              </Grid>
              <Grid item lg={8}>
                <Typography variant="h2">
                  Further Information that we require
                </Typography>
              </Grid>
              <Grid item className={classes.fields}>
                <FormTextInput
                  fieldName="registrationNumber"
                  value={formik.values.registrationNumber}
                  label="Registration number, if applicable"
                  handleChange={formik.handleChange}
                  error={false}
                />

                <RadioList
                  fieldName="onlineService"
                  value={formik.values.onlineService}
                  label="Is your organisation an online/helpline only service?"
                  onChange={(event) => {
                    formik.setFieldValue('onlineService', event.target.value);
                  }}
                  options={ORGANISATION_ONLINE_OPTIONS}
                  error={
                    formik.touched.onlineService && formik.errors.onlineService
                  }
                />

                {formik.values.onlineService ===
                  ORGANISATION_ONLINE_OPTIONS[1] && (
                  <RadioList
                    fieldName="locationCount"
                    value={formik.values.locationCount}
                    label="How many physical locations/drop-in centres do you have?"
                    onChange={(event) => {
                      formik.setFieldValue('locationCount', event.target.value);
                    }}
                    options={LOCATION_COUNT_OPTIONS}
                    error={
                      formik.touched.locationCount &&
                      formik.errors.locationCount
                    }
                  />
                )}

                <RadioList
                  fieldName="serviceReach"
                  value={formik.values.serviceReach}
                  label="Is your service national or regional?"
                  onChange={(event) => {
                    formik.setFieldValue('serviceReach', event.target.value);
                  }}
                  options={SERVICE_REACH_OPTIONS}
                  error={
                    formik.touched.serviceReach && formik.errors.serviceReach
                  }
                />

                {formik.values.serviceReach === SERVICE_REACH_OPTIONS[1] && (
                  <>
                    <div
                      className={classes.error}
                      aria-label="serviceRegions-error"
                    >
                      {formik.errors.serviceRegions || '\u00A0'}
                    </div>
                    <FormControl component="fieldset">
                      <FormLabel
                        component="legend"
                        className={classes.radioLegend}
                      >
                        Please select the regions(s) your service covers or is
                        based in
                      </FormLabel>
                      <FormGroup>
                        {Object.keys(formik.values.serviceRegions).map(
                          (region) => (
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={formik.values.serviceRegions[region]}
                                  onChange={(event) => {
                                    formik.setFieldValue('serviceRegions', {
                                      ...formik.values.serviceRegions,
                                      [region]: event.target.checked,
                                    });
                                  }}
                                  name={region}
                                  className={classes.checkbox}
                                  color="primary"
                                />
                              }
                              label={region}
                              key={region}
                              className={classes.checkboxLabel}
                            />
                          ),
                        )}
                      </FormGroup>
                    </FormControl>
                  </>
                )}

                <FormTextInput
                  fieldName="patientReferrals"
                  handleChange={formik.handleChange}
                  value={formik.values.patientReferrals}
                  error={
                    !!formik.touched.patientReferrals &&
                    formik.errors.patientReferrals
                  }
                  showErrorText
                  label="How are your patients referred?"
                  multiLine
                  rows={10}
                  maxCharacters={MAX_PATIENT_REFERRALS_LENGTH}
                  showCharacterCount
                  handleBlur={formik.handleBlur}
                />

                <FormTextInput
                  fieldName="ongoingServices"
                  handleChange={formik.handleChange}
                  value={formik.values.ongoingServices}
                  error={
                    !!formik.touched.ongoingServices &&
                    formik.errors.ongoingServices
                  }
                  showErrorText
                  label="Are your services ongoing?"
                  multiLine
                  rows={10}
                  maxCharacters={MAX_ONGOING_SERVICES_LENGTH}
                  showCharacterCount
                  handleBlur={formik.handleBlur}
                />

                <DatePicker
                  handleChange={(date) =>
                    formik.setFieldValue('date', date, !!formik.values.date)
                  }
                  error={formik.errors.date}
                  legend="Date your organisation was established/opened:"
                />

                <FormTextInput
                  fieldName="howFunded"
                  handleChange={formik.handleChange}
                  value={formik.values.howFunded}
                  error={!!formik.touched.howFunded && formik.errors.howFunded}
                  showErrorText
                  label="How are you funded?"
                  multiLine
                  rows={10}
                  maxCharacters={MAX_HOW_FUNDED_LENGTH}
                  showCharacterCount
                  handleBlur={formik.handleBlur}
                />

                <RadioList
                  fieldName="freeService"
                  value={formik.values.freeService}
                  label="Are the services offered for free?"
                  onChange={(event) => {
                    formik.setFieldValue('freeService', event.target.value);
                  }}
                  options={FREE_SERVICE_OPTIONS}
                  error={
                    formik.touched.freeService && formik.errors.freeService
                  }
                />
              </Grid>

              <br />
              <br />

              <Grid item lg={8}>
                <Typography variant="h2">
                  Main contact for us to ensure your listing is kept up to date
                </Typography>
                <Typography variant="body1" className={classes.noMargin}>
                  By giving us this information you consent to us holding your
                  details in order to keep your listing updated and update your
                  on the development of Cancer Care Map. We will not contact you
                  for any other reason or pass your information to a third party
                </Typography>
              </Grid>

              <Grid item className={classes.fields}>
                <FormTextInput
                  fieldName="name"
                  handleChange={formik.handleChange}
                  value={formik.values.name}
                  error={!!formik.touched.name && formik.errors.name}
                  showErrorText
                  label="Name"
                  boldLabel
                  textFieldInputProps={{ maxLength: MAX_NAME_LENGTH }}
                  handleBlur={formik.handleBlur}
                />

                <FormTextInput
                  fieldName="email"
                  handleChange={formik.handleChange}
                  value={formik.values.email}
                  error={!!formik.touched.email && formik.errors.email}
                  showErrorText
                  label="Email"
                  boldLabel
                  handleBlur={formik.handleBlur}
                />

                <FormTextInput
                  fieldName="phone"
                  handleChange={formik.handleChange}
                  value={formik.values.phone}
                  error={!!formik.touched.phone && formik.errors.phone}
                  showErrorText
                  label="Phone number"
                  boldLabel
                  tooltip
                  tooltipText="Please include your UK number"
                  handleBlur={formik.handleBlur}
                />
                <div className={classes.socialMediaChannelsContainer}>
                  <FormTextInput
                    fieldName="socialMediaChannels"
                    handleChange={formik.handleChange}
                    value={formik.values.socialMediaChannels}
                    error={
                      !!formik.touched.socialMediaChannels &&
                      formik.errors.socialMediaChannels
                    }
                    label="Please include your social media channels"
                    multiLine
                    rows={10}
                    maxCharacters={MAX_SOCIAL_MEDIA_CHANNELS_LENGTH}
                    showCharacterCount
                    tooltip
                    tooltipText="(facebook, twitter, Instagram etc.)"
                    handleBlur={formik.handleBlur}
                  />
                </div>
              </Grid>
              <RadioList
                fieldName="writeBlog"
                value={formik.values.writeBlog}
                label="Would you be willing to write a blog for us to feature on the website?"
                onChange={(event) => {
                  formik.setFieldValue('writeBlog', event.target.value);
                }}
                options={WRITE_BLOG_OPTIONS}
                error={formik.touched.writeBlog && formik.errors.writeBlog}
              />
              <Grid item lg={8}>
                <FormPrivacyCheckbox
                  error={formik.errors.checked}
                  handleChange={handleChange}
                  checked={checked}
                />

                <div data-test-id="recaptcha-container">
                  <RecaptchaInput
                    errorMessage={
                      formik.submitCount > 0 && formik.errors.recaptcha
                    }
                    onChange={(response) => validateRecapture(response)}
                  />
                </div>

                <button
                  type="submit"
                  className={classes.submitButton}
                  onClick={() => scrollToError(formik.errors)}
                >
                  Submit
                </button>
              </Grid>
            </form>
          </Grid>
        </div>
      ) : (
        <Grid item xs={12} sm={8}>
          {submitSuccessful === true && (
            <FormSubmission
              messageTitle="Thank you for contacting us"
              messageBody={
                <Typography
                  variant="body2"
                  data-testid="submit-success"
                  style={{ lineHeight: '140%' }}
                >
                  Thank you very much for contacting the team at
                  cancercaremap.org
                  <br />
                  Your email has been received and we will get back to you as
                  soon as possible - please note that we are a small team
                  therefore it may take a few days to process your enquiry.
                  <br />
                  If there is something urgent you would like to speak to us
                  about please email us directly at hello@cancercaremap.org
                  <br />
                  Thank you very much for contacting the team at
                  cancercaremap.org
                </Typography>
              }
              pageLinks={[
                { pageLink: '/', pageTitle: 'Go to the home page' },
                { pageLink: '/search', pageTitle: 'Start a new search' },
              ]}
            />
          )}

          {submitSuccessful === false && (
            <FormSubmission
              messageTitle="There has been an error with your submission."
              messageBody={
                <>
                  <Typography
                    variant="body2"
                    data-testid="submit-fail"
                    style={{ lineHeight: '140%' }}
                  >
                    Unfortunately something went wrong and we were unable to
                    submit your application form. Please click ‘Return to Add A
                    Service page’ and submit your details again. Apologies for
                    the inconvenience.
                  </Typography>
                  <br />
                  <button
                    type="button"
                    data-testid="add-service-submit"
                    onClick={() => {
                      setSubmitSuccessful(null);
                      setChecked(false);
                    }}
                    className={classes.hyperLink}
                  >
                    Return to Add A Service page
                  </button>
                </>
              }
            />
          )}
        </Grid>
      )}
    </Layout>
  );
};

export default AddServiceForm;
