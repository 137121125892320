import { isPast, isValid } from 'date-fns';

export const GENERIC_EMPTY_TEXT_FIELD_ERROR_STRING =
  'Please fill in this field';
export const INVALID_DATE_ERROR_STRING = 'Please enter a valid date';
export const MAX_URL_LENGTH = 2048;
export const INVALID_URL_ERROR_STRING = 'Please enter a valid url';
export const INVALID_EMAIL_ERROR_STRING = 'Please enter a valid email';
export const INVALID_PHONE_NUMBER_ERROR_STRING =
  'Please enter a valid phone number';

export const MAX_IMAGE_SIZE = 2097152; // 2MB;
export const MAX_IMAGE_SIZE_EXCEEDED_ERROR_STRING =
  'Please upload an image smaller than 2MB.';
export const SUPPORTED_IMAGE_MIME_TYPES = [
  'image/jpeg',
  'image/png',
  'image/gif',
];
export const UNSUPPORTED_IMAGE_MIME_TYPE_ERROR_STRING =
  'Please upload a file in one of the following formats: GIF, JPG, JPEG, or PNG.';
export const NO_LOGO_ATTACHED = 'Please upload a company logo';

export const textFieldError = (
  value,
  options = { mandatory: false, characterLimit: 0 },
) => {
  if (options.mandatory && value.length === 0) {
    return GENERIC_EMPTY_TEXT_FIELD_ERROR_STRING;
  }

  if (options.characterLimit && value.length > options.characterLimit) {
    return true;
  }

  return undefined;
};

export const fileTypeError = (fileType) => {
  if (!SUPPORTED_IMAGE_MIME_TYPES.includes(fileType)) {
    return UNSUPPORTED_IMAGE_MIME_TYPE_ERROR_STRING;
  }

  return undefined;
};

export const fileMaxSizeError = (fileSize) => {
  if (fileSize > MAX_IMAGE_SIZE) {
    return MAX_IMAGE_SIZE_EXCEEDED_ERROR_STRING;
  }

  return undefined;
};

export const dateFieldError = (date) => {
  const [inputDay, inputMonth, inputYear] = date.split('/').map(Number);

  const adjustedMonth = inputMonth - 1;

  const inputDate = new Date(inputYear, adjustedMonth, inputDay);

  if (
    !inputDay ||
    !inputYear ||
    inputYear.toString().length !== 4 ||
    inputDate.getMonth() !== adjustedMonth ||
    !isValid(inputDate) ||
    !isPast(inputDate)
  ) {
    return INVALID_DATE_ERROR_STRING;
  }

  return undefined;
};

export const urlFieldError = (url) => {
  if (
    !/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\\.-]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/.test(
      url,
    ) ||
    url.length > MAX_URL_LENGTH
  ) {
    return INVALID_URL_ERROR_STRING;
  }

  return undefined;
};

export const emailFieldError = (email) => {
  if (
    !email ||
    !/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(email)
  ) {
    return INVALID_EMAIL_ERROR_STRING;
  }

  return undefined;
};

export const phoneFieldError = (phoneNumber) => {
  const sanitisedPhoneNumber = phoneNumber.replace(/\s/g, '');

  if (!phoneNumber || !/^(0\s?\d{4,10})?$/.test(sanitisedPhoneNumber)) {
    return INVALID_PHONE_NUMBER_ERROR_STRING;
  }

  return undefined;
};

export const validateLogo = (companyLogo) => {
  if (!companyLogo) {
    return 'Please upload a company logo';
  }

  // companyLogo is of type `File`
  const typeError = fileTypeError(companyLogo.type);
  const maxSizeError = fileMaxSizeError(companyLogo.size);

  if (typeError) {
    return typeError;
  }

  if (maxSizeError) {
    return maxSizeError;
  }

  return undefined;
};
